import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import logo from "./images/logo_no_text_no_outline_no_background.png";
import MenuIcon from "@mui/icons-material/Menu";

const pages = [
  { title: "Home", path: "home" },
  { title: "Photos", path: "photos" },
  { title: "Escape Rooms", path: "escaperooms" },
];

export default function NavigationBar() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setAnchorElNav(null);
  };

  return (
    <AppBar color="secondary" position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="camp somos menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            {pages.map(({ title, path }) => (
              <MenuItem key={path} onClick={() => handleNavigate(path)}>
                <Typography color="secondary" textAlign="center">
                  {title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Container
          component="img"
          alt="Camp Somos"
          src={logo}
          sx={{
            height: 50,
            width: "auto",
          }}
        />
        <Typography
          variant="h5"
          noWrap
          component="a"
          href="home"
          sx={{
            mr: 2,
            display: { xs: "flex", md: "none" },
            flexGrow: 1,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Camp Somos
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map(({ title, path }) => (
            <Button
              key={path}
              onClick={() => handleNavigate(path)}
              sx={{ my: 2, display: "block", mr: 2 }}
            >
              <Typography variant="h6">{title}</Typography>
            </Button>
          ))}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
