import * as React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import ComingSoon from "./pages/ComingSoon";
import Photos from "./pages/Photos";
import theme from "./ColorTheme";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Layout from "./Layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" />,
      },
      {
        path: "photos",
        element: <Photos bucket_name={"wedding_photos"}/>,
      },
      {
        path: "escaperooms",
        element: <Photos bucket_name={"escape_room_photos"}/>,
      },
      {
        path: "home",
        element: <ComingSoon />,
      },
    ],
  },
]);

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
