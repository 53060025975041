import logo from "../images/logo_with_text_background.png";
import { Container, Typography } from "@mui/material";

export default function ComingSoon() {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Typography align="center" variant="h2" color="primary.contrastText">
        Site Coming Soon
      </Typography>
      <Container
        component="img"
        alt="Camp Somos"
        src={logo}
        sx={{ width: "60%" }}
      />

      <Typography align="center" variant="h4" color="primary.contrastText">
        Wedding: September 6, 2025
      </Typography>
    </Container>
  );
}
