import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#877E4C",
      main: "#2D4539",
      dark: "#303030",
      contrastText: "#F5F2Eb",
    },
    secondary: {
      light: "#E3AD24",
      main: "#D05028",
      // dark: '#ba000d',
      contrastText: "#303030",
    },
    background: {
      default: "#2D4539",
      paper: "#F7E4C5",
    },
  },
  typography: {
    fontFamily: '"Bogart", sans-serif',
    fontWeightRegular: 900,
    fontWeightLight: 900,
    fontWeightMedium: 900,
    fontWeightBold: 900,
  },
});

export default theme;
